<template>
  <BaseModal @close="$emit('close')" class="feedback-modal">
    <div class="feedback-modal__wrap">
      <div class="feedback-modal__title">Запись на прием</div>
      <div class="feedback-modal__description">
        Оставьте заявку и мы свяжемся<br />с Вами в ближайшее время!
      </div>
      <input
        v-model="name"
        :class="{
          'feedback-modal__input': true,
          'no-empty': !!name
        }"
        type="text"
        placeholder="Введите имя"
      />
      <input
        v-model="phone"
        :class="{
          'feedback-modal__input': true,
          'no-empty': !!phone
        }"
        type="tel"
        placeholder="Введите телефон"
      />
      <button
        class="feedback-modal__btn btn"
        @click="submit"
        :disabled="disabled"
      >
        {{ isLoading ? 'Подождите...' : 'Записаться на прием' }}
      </button>
    </div>
  </BaseModal>
</template>

<script>
  import BaseModal from './BaseModal'
  import SuccessModal from './SuccessModal'
  import axios from 'axios'

  export default {
    components: {
      BaseModal
    },
    computed: {
      disabled() {
        return !(this.phone && this.name) || this.isLoading
      }
    },
    data() {
      return {
        phone: '',
        name: '',
        isLoading: false
      }
    },
    methods: {
      showSuccess() {
        this.$modal.show(
          SuccessModal,
          {},
          {
            height: 478,
            width: 392
          }
        )
      },
      async submit() {
        if (this.disabled) {
          return
        }
        this.isLoading = true

        await axios.post('email/contact/', {
          phone: this.phone,
          name: this.name
        })

        this.isLoading = false

        this.$emit('close')
        this.showSuccess()
      }
    }
  }
</script>
