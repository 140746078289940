import Vue from 'vue'
import LazyYoutubeVideo from 'vue-lazy-youtube-video'
import App from './layout/App'
import Example from './ui/Example'
import ProductsSlider from './sliders/ProductsSlider'
import AboutSlider from './sliders/AboutSlider'
import ProductsTable from './ui/ProductsTable'

import DynamicMarquee from 'vue-dynamic-marquee'
;[
  App,
  {
    name: 'example',
    ...Example
  },
  ProductsSlider,
  AboutSlider,
  ProductsTable
  // if not component name
  // { name: 'component-name', ...Component }
].forEach((Component) => {
  if (!Component.name) {
    throw new Error(`Not component name: ${Component}`)
  }

  Vue.component(Component.name, Component)
})

Vue.component('lazyyoutubevideo', LazyYoutubeVideo)
Vue.component('dmarquee', DynamicMarquee)
