export const state = {
  items: [
    {
      name: 'Links',
      items: [
        {
          icon: 'mdi mdi-file-code',
          name: 'Static page',
          url: '/pages/example/'
        },
        {
          icon: 'mdi mdi-speedometer',
          name: 'Django admin',
          url: '/dj-admin/'
        },
        {
          icon: 'mdi mdi-bird',
          name: 'Wagtail admin',
          url: '/wg-admin/'
        },
        {
          icon: 'mdi mdi-api',
          name: 'API',
          url: '/api/'
        },
        {
          icon: 'mdi mdi-database',
          name: 'SQL',
          url: '/sql/'
        }
      ]
    },
    {
      name: 'Documents',
      items: [
        {
          icon: 'mdi mdi-language-python',
          name: 'Django',
          url: 'https://www.djangoproject.com/'
        },
        {
          icon: 'mdi mdi-api',
          name: 'Django REST Framework',
          url: 'https://www.django-rest-framework.org/'
        },
        {
          icon: 'mdi mdi-bird',
          name: 'Wagtail',
          url: 'https://docs.wagtail.io/en/latest/index.html'
        },
        {
          icon: 'mdi mdi-bootstrap',
          name: 'Bootstrap',
          url: 'https://getbootstrap.com/'
        },
        {
          icon: 'mdi mdi-vuejs',
          name: 'Vue.js',
          url: 'https://ru.vuejs.org/'
        },
        {
          icon: 'mdi mdi-vuejs',
          name: 'Vuex',
          url: 'https://vuex.vuejs.org/ru/guide/'
        },
        {
          icon: 'mdi mdi-code-braces',
          name: 'Axios',
          url: 'https://github.com/axios/axios'
        },
        {
          icon: 'mdi mdi-jquery',
          name: 'JQuery',
          url: 'https://api.jquery.com/'
        },
        {
          icon: 'mdi mdi-color-helper',
          name: 'Lodash',
          url: 'https://lodash.com/'
        },
        {
          icon: 'mdi mdi-comment-alert',
          name: 'SweetAlert',
          url: 'https://sweetalert2.github.io/'
        },
        {
          icon: 'mdi mdi-material-design',
          name: 'Material design icons',
          url: 'https://pictogrammers.github.io/@mdi/font/5.6.55/'
        },
        {
          icon: 'mdi mdi-view-carousel-outline',
          name: 'Splide JS',
          url: 'https://splidejs.com/'
        }
      ]
    }
  ]
}

export const getters = {
  items: (state) => state.items
}
