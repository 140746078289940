<template>
  <BaseModal @close="$emit('close')" class="review-card__modal">
    <div class="review-card review-card--modal">
      <div class="review-card__header">
        <i class="ic ic-user-circle review-card__icon"></i>
        <div class="review-card__header-wrap">
          <div class="review-card__star-container">
            <i
              v-for="index in rating"
              :key="index"
              class="ic ic-star review-card__star"
            ></i>
          </div>
          <div class="review-card__name">
            {{ name }}
          </div>
        </div>
      </div>
      <div
        class="review-card__body review-card__body--modal"
        v-html="text"
      ></div>
    </div>
  </BaseModal>
</template>

<script>
  import BaseModal from './BaseModal'

  export default {
    components: {
      BaseModal
    },
    props: {
      rating: {
        type: Number
      },
      name: {
        type: String
      },
      text: {
        type: String
      }
    }
  }
</script>
