import './axios'
import 'bootstrap'
import './jquery'
import './iconfont'
import './vue-js-modal'

import Vue from 'vue'
import VueSplide from '@splidejs/vue-splide'
import VueDragscroll from 'vue-dragscroll'

Vue.use(VueSplide)
Vue.use(VueDragscroll)

window.Popper = require('popper.js').default
