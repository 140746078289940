<template>
  <BaseModal @close="$emit('close')" class="success-modal">
    <img
      class="success-modal__icon"
      src="/public/static/images/success.png"
      alt=""
    />

    <div class="success-modal__wrap">
      <div class="success-modal__title">Спасибо за Вашу заявку!</div>
      <div class="success-modal__description">
        Данные получены, мы свяжемся<br />с Вами в ближайшее время.
      </div>
    </div>
  </BaseModal>
</template>

<script>
  import BaseModal from './BaseModal'

  export default {
    components: {
      BaseModal
    }
  }
</script>
