<template>
  <BaseModal @close="$emit('close')"> поиск </BaseModal>
</template>

<script>
  import BaseModal from './BaseModal'

  export default {
    components: {
      BaseModal
    }
  }
</script>
