<template>
  <div class="products-table">
    <div class="products-table__categories">
      <Splide :options="options">
        <SplideSlide>
          <div
            :class="{
              'products-table__button': true,
              'products-table__button--active': selectedCategory === null
            }"
            @click="reset"
          >
            Все
          </div>
        </SplideSlide>
        <SplideSlide v-for="categorie in categories" :key="categorie.name">
          <div
            :class="{
              'products-table__button': true,
              'products-table__button--active': selectedCategory === categorie
            }"
            @click="changeCategorie(categorie)"
          >
            {{ categorie.name }}
          </div>
        </SplideSlide>

        <template #controls>
          <div class="splide__arrows">
            <button class="splide__arrow splide__arrow--prev">
              <i class="mdi mdi-chevron-left"></i>
            </button>
            <button class="splide__arrow splide__arrow--next">
              <i class="mdi mdi-chevron-right"></i>
            </button>
          </div>
        </template>
      </Splide>
    </div>
    <div class="products-table__table">
      <vuescroll :ops="ops">
        <div
          class="products-table__item"
          v-for="(product, index) in products"
          :key="index"
        >
          <div class="products-table__name">
            {{ product.name }}
          </div>
          <div class="products-table__price">{{ product.price }}&nbsp;руб.</div>
        </div>
      </vuescroll>
    </div>
  </div>
</template>

<script>
  import vuescroll from 'vuescroll'
  import { Splide, SplideSlide } from '@splidejs/vue-splide'

  export default {
    name: 'productstable',
    components: {
      vuescroll,
      Splide,
      SplideSlide
    },
    props: {
      categories: {
        type: Array,
        required: true
      }
    },
    computed: {
      products() {
        if (this.selectedCategory) {
          return this.selectedCategory.products
        }
        return this.categories
          .map((value) => value.products)
          .flat()
          .filter((value) => value)
      }
    },
    data() {
      return {
        options: {
          arrows: true,
          autoplay: false,
          autoWidth: true,
          pagination: false,
          // rewind: true,
          type: 'slide',
          gap: 10
        },
        selectedCategory: null,
        ops: {
          vuescroll: {
            mode: 'native',
            sizeStrategy: 'number'
          },
          rail: {
            background: '#040E41',
            opacity: 0.1,
            size: '5px',
            specifyBorderRadius: false,
            gutterOfEnds: null,
            gutterOfSide: '2px',
            keepShow: false
          },
          bar: {
            showDelay: 500,
            onlyShowBarOnScroll: false,
            keepShow: true,
            background: '#F07730',
            opacity: 1,
            hoverStyle: false,
            specifyBorderRadius: false,
            minSize: 0,
            size: '5px',
            disable: false
          }
        }
      }
    },
    methods: {
      changeCategorie(categorie) {
        this.selectedCategory = categorie
      },
      reset() {
        this.selectedCategory = null
      }
    }
  }
</script>
