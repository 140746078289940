<template>
  <div class="base-modal">
    <div class="base-modal__close">
      <i
        class="ic ic-close base-modal__close-button"
        @click="$emit('close')"
      ></i>
    </div>
    <div class="base-modal__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {}
</script>
