<template>
  <div class="products__list">
    <Splide :options="options">
      <SplideSlide v-for="(slide, index) in slides" :key="index">
        <a :href="slide.link ? slide.link : '#'" class="products__card">
          <div class="products__icon">
            <img :src="slide.icon" :alt="slide.name" />
          </div>
          <div class="products__name">
            {{ slide.name }}
          </div>
        </a>
      </SplideSlide>
    </Splide>
  </div>
</template>

<script>
  import { Splide, SplideSlide } from '@splidejs/vue-splide'

  export default {
    name: 'productsslider',
    components: {
      Splide,
      SplideSlide
    },
    props: {
      slides: Array
    },
    data() {
      return {
        options: {
          arrows: false,
          autoplay: true,
          fixedWidth: 250,
          pagination: false,
          rewind: true,
          type: 'loop',
          perMove: 1,
          gap: 30
        }
      }
    }
  }
</script>
