import Vue from 'vue'
import store from '@/store'

import '@/plugins'
import '@/components'

import FeedbackModal from '@/components/modals/FeedbackModal'
import SearchModal from '@/components/modals/SearchModal'
import ReviewFeedbackModal from '@/components/modals/ReviewFeedbackModal'
import ReviewModal from '@/components/modals/ReviewModal'

Vue.config.productionTip = false

/* eslint-disable */
if ($('#app').length) {
  new Vue({
    el: '#app',
    store,
    methods: {
      showFeedbackModal() {
        this.$modal.show(
          FeedbackModal,
          {},
          {
            scrollable: true,
            height: 'auto',
            width: 392
          }
        )
      },
      showSearchModal() {
        this.$modal.show(
          SearchModal,
          {},
          {
            scrollable: true,
            height: 'auto',
            width: 392
          }
        )
      },
      showReviewModal(rating, name, text) {
        this.$modal.show(
          ReviewModal,
          {
            rating: rating,
            name: name,
            text: text
          },
          {
            scrollable: true,
            height: 'auto',
            width: 570
          }
        )
      },
      showReviewFeedbackModal() {
        this.$modal.show(ReviewFeedbackModal)
      }
    }
  })
}
/* eslint-enable */
