<template>
  <Splide :options="options">
    <SplideSlide
      class="about__slide"
      v-for="(slide, index) in slides"
      :key="index"
    >
      <img class="about__image" :src="slide" alt="" />
    </SplideSlide>
  </Splide>
</template>

<script>
  import { Splide, SplideSlide } from '@splidejs/vue-splide'

  export default {
    name: 'aboutslider',
    components: {
      Splide,
      SplideSlide
    },
    props: {
      slides: Array
    },
    data() {
      return {
        options: {
          arrows: false,
          autoplay: true,
          pagination: true,
          rewind: true,
          type: 'loop',
          perPage: 1,
          perMove: 1,
          cover: true,
          height: 416
        }
      }
    }
  }
</script>
