import Vue from 'vue'
import VModal from 'vue-js-modal'

Vue.use(VModal, {
  dialog: true,
  dynamic: true,
  adaptive: true,
  scrollable: true,
  height: 'auto',
  injectModalsContainer: true,
  dynamicDefaults: { foo: 'foo' }
})
